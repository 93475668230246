@import 'src/shared';

.Term {
    @include borders;
    max-width: 150px;
}

.PointsSelector {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 30px;
    box-sizing: border-box;
}
.PointsSelector-points{
    user-select: none;
}
